import { LOAD_STATS, LOADING_STATS } from "./constants";

const initialState = {
  dashValue: {
    activeFeeds: 0,
    inactiveFeeds: 0,
    totalFeeds: 0,
  },
  loading: false,
  error: null,
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_STATS:
      return {
        ...state,
        dashValue: payload,
        loading: false,
      };

    case LOADING_STATS:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
