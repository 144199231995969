import {
  LOADING_FEEDS,
  LOAD_FEEDS,
  SET_CURRENT_PAGE,
  UPDATE_FEED,
} from './constants';

const initialState = {
  feeds: [],
  totalFeeds: 0,
  currentPage: 1,
  loading: false,
  error: null,
};

export default function feeds(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FEEDS:
      return {
        ...state,
        feeds: payload.data,
        totalFeeds: payload.totalFeeds,
        loading: false,
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };

    case LOADING_FEEDS:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_FEED:
      return {
        ...state,
        feeds: state.feeds.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    default:
      return state;
  }
}
