import { LOAD_CATEGORY, LOADING_CATEGORY } from "./constants";

const initialState = {
  category: [],
  loading: false,
  error: null,
};

export default function category(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
      };

    case LOADING_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
