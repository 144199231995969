import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  GET_USERS,
  GET_ME,
  SET_ACCOUNT,
} from './constants';
const accounts =[
  { id: 1, username: 'almashhadmedia', icon: 'cil-media-play' },
  { id: 2, username: 'almashhadsports', icon: 'cil-football' },
]
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: false,
  user: {},
  users: [],
  me: {},
  accounts: accounts,
  account: accounts[0],
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {},
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case GET_ME:
      return {
        ...state,
        me: payload.data,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        account: payload,
      };
    default:
      return state;
  }
}
